import React from "react";
import { styled } from "../../../styles";
import { Link } from "../../../components/Link";
import { TileTitle, TileFact, Glass } from "../../../components";
import { SpecialInterface } from "../SpecialInterface";

interface SpecialProps {
  special: SpecialInterface;
  lang: "de" | "en";
}

export const SpecialMobile: React.FC<SpecialProps> = ({ special, lang }) => {
  if (
    !special.preview.url ||
    !special.preview.url.image ||
    !special.preview.url.image.fluid
  ) {
    return null;
  }

  return (
    <Link to={`/specials/${special.slug}/`}>
      <SpecialContainer
        id={special.slug}
        src={special.preview.url.image.fluid.src}
      >
        <TileTitle>
          <Glass>{special.nameVisible}</Glass>
        </TileTitle>

        <TileFact>
          <Glass>
            <strong>{special.client}</strong>
          </Glass>
        </TileFact>
      </SpecialContainer>
    </Link>
  );
};

const SpecialContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100vw;
  height: 100%;
  min-height: 375px;
  max-height: 450px;
  padding: 1.4rem 1rem;
  margin: 0 -40px 20px -40px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: ${({ src }: { src: string }) => `url(${src})`};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  will-change: transform;
`;

const SpecialImageContainer = styled.div`
  width: 100%;
`;
