import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Trans } from "@lingui/macro";
import { styled, media, angle, fonts } from "../../styles";
import { useWidth } from "../../hooks";
import { smoothScrollToAnchor } from "../../utils";
import { Page, Content, TitleImage, List, ListItem } from "../../components";
import { Special, SpecialMobile } from "./components";
import { SpecialInterface } from "./SpecialInterface";
import specialsImg from "./assets/Illu_Specials.svg";

interface SpecialsPageProps {
  pageContext: { lang: "de" | "en" };
}

export const SpecialsPage: React.FC<SpecialsPageProps> = ({
  pageContext: { lang },
  ...props
}) => {
  const {
    vhsImg: {
      image: {
        fluid: { src: vhsImg }
      }
    },
    specials: { edges: specialNodes }
  }: {
    specials: {
      edges: [
        {
          node: SpecialInterface;
        }
      ];
    };
    vhsImg: {
      image: {
        fluid: {
          src: string;
        };
      };
    };
  } = useStaticQuery(graphql`
    query specialsQuery {
      vhsImg: file(relativePath: { eq: "specials/vhskonfetti.png" }) {
        image: childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      specials: allSpecialsJson {
        edges {
          node {
            id
            name
            nameVisible
            title
            client
            description {
              de
              en
            }
            slug
            tags
            preview {
              url {
                id
                image: childImageSharp {
                  fluid(maxWidth: 900, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const pageX = useWidth();

  return (
    <Page
      title="Specials"
      description="Unser latest shit: Cases zu dynamischer Musik, 360° Audio, Virtual Reality, Augmented Reality, Chatbots & Conversational Interfaces."
      seoTitle="Cases | 360° Audio, VR, AR, Chatbots | German Wahnsinn"
      keywords={[
        "German Wahnsinn",
        "Cases",
        "Arbeiten",
        "dynamische Musik",
        "360° Audio",
        "Virtual Reality",
        "Augmented Reality",
        "VR",
        "AR",
        "Chatbots",
        "Conversational Interfaces",
        "Peta"
      ]}
      headerSkull="Specials"
      {...props}
    >
      <Content>
        <SpecialTitleImage title="Specials" src={specialsImg} />

        <Vhs src={vhsImg} />

        <SpecialsIntroContainer>
          <SpecialsIntro>
            <Trans>
              Kein Weg ist uns zu weit und keine Herausforderung zu groß. Wir
              sind Fans der technischen Innovation und haben eigens dafür 2014
              eine Digital Audio Unit gegründet. Von Instrumentenbau über
              dynamische Musik, 360° Audio, Virtual Reality, Augmented Reality,
              Chatbots bis zu Conversational Interfaces. Also wenn du mal raus
              aus der Comfort Zone willst und dich fragst „Wo ist VR, wo ist AR,
              wo ist denn der latest shit?!“ – hier kommen unsere Cases.
            </Trans>
          </SpecialsIntro>

          <SpecialsList columns={[2]}>
            {specialNodes.map(({ node: special }) => (
              <SpecialShortcut key={special.slug}>
                <div>
                  <a href={`#${special.slug}`} onClick={smoothScrollToAnchor}>
                    <strong>{special.client}</strong>
                    <br />
                    {special.title}
                  </a>
                </div>
              </SpecialShortcut>
            ))}
          </SpecialsList>
        </SpecialsIntroContainer>

        <SpecialsListContainer>
          {specialNodes.map(({ node: special }) => {
            return pageX >= 768 ? (
              <Special
                special={special}
                key={special.slug}
                lang={lang || "de"}
              />
            ) : (
              <SpecialMobile
                special={special}
                key={special.slug}
                lang={lang || "de"}
              />
            );
          })}
        </SpecialsListContainer>
      </Content>
    </Page>
  );
};

const SpecialTitleImage = styled(TitleImage)`
  width: 90%;
  margin: 1rem 0 2rem 0;

  ${media.tablet} {
    margin: 0 0 2rem 0;
    width: 83.333%;
  }

  ${media.landscape} {
    width: 50%;
    margin: 0 0 2rem 1rem;
  }

  ${media.laptop} {
    margin: -1rem 0 2rem 0;
    width: 50%;
  }

  ${media.desktop} {
    margin: -2rem 0 2rem 0;
  }
`;

const SpecialsIntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.tablet} {
    width: 60%;
  }
`;

const SpecialsIntro = styled.p`
  margin-bottom: 3rem;

  ${media.tablet} {
    margin-bottom: 6rem;
    ${angle(3)};
  }

  ${media.laptop} {
    ${angle(-4)};
  }

  ${media.landscape} {
    ${angle(0)};
  }
`;

const SpecialsListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SpecialsList = styled(List)`
  /* display: none; */

  margin-bottom: 4rem;

  @media (max-width: 425px) {
    columns: 1;
  }

  ${media.tablet} {
    display: block;
    margin-bottom: 6rem;
    margin-left: 3rem;
  }
`;

const SpecialShortcut = styled(ListItem)`
  line-height: 1.2;
  margin-bottom: 1rem;
  font-size: 15px;

  ${media.desktop} {
    font-size: 18px;
  }

  strong {
    font-size: 120%;
    font-family: ${fonts.vario};
  }
`;

const Vhs = styled.img`
  display: none;
  width: 100%;
  height: auto;

  ${media.tablet} {
    display: block;
    position: absolute;
    top: 150px;
    right: -55%;
  }

  ${media.laptop} {
    top: 0;
    right: -50%;
  }
`;
