import React from "react";
import { Trans } from "@lingui/macro";
import { styled, media, angle } from "../../../styles";
import { Link, Image, ShadowedBox } from "../../../components";
import { SpecialInterface } from "../SpecialInterface";
import postitImg from "../assets/PostIT_Single.png";
import flameImg from "../assets/Flame_ani.svg";

interface IProps {
  special: SpecialInterface;
  lang: "de" | "en";
}

export const Special: React.FC<IProps> = ({ special, lang }) => (
  <SpecialContainer id={special.slug}>
    <SpecialImageContainer>
      <Link to={`/specials/${special.slug}/`} style={{ display: "block" }}>
        {special.preview && special.preview.url && (
          <Image fluid={special.preview.url.image.fluid} />
        )}
      </Link>
    </SpecialImageContainer>

    <PostIt>
      <PostItContent>
        <Link to={`/specials/${special.slug}/`} state={{ isModal: true }}>
          <PostItHeading>{special.nameVisible}</PostItHeading>
        </Link>

        <PostItTags>
          {special.tags.map(tag => (
            <PostItTag key={tag}>{tag}</PostItTag>
          ))}
        </PostItTags>

        <PostItCopy>{special.description[lang]}</PostItCopy>

        <PostItLink
          to={`/specials/${special.slug}/`}
          state={{ isModal: true }}
          display="inline-block"
          style={{
            whiteSpace: "nowrap"
          }}
        >
          <Trans>GIB MIR MEHR</Trans>
          <Image src={flameImg} width={["50px"]} mb="2rem" />
        </PostItLink>
      </PostItContent>
    </PostIt>
  </SpecialContainer>
);

const PostItContent = styled.div`
  margin: 3rem;
`;

const PostItCopy = styled.p``;

const PostItTags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PostItTag = styled.span`
  font-size: 13px;
  line-height: 15px;

  ${media.tablet} {
    font-size: 15px;
    line-height: 18px;
  }

  ${media.laptop} {
    font-size: 18px;
    line-height: 23px;
  }

  ${media.desktop} {
    font-size: 23px;
    line-height: 28px;
  }
`;

const PostItLink = styled(Link)`
  margin-top: 3rem;
`;

const PostIt = styled.div`
  background-image: url(${postitImg});
  background-position: right bottom;
  background-size: 100% 100%;

  align-self: center;
  z-index: 10;

  width: 100%;
  margin-top: 4rem;
  padding: 0;

  ${angle(3)};

  ${media.tablet} {
    width: 70%;
    padding: 0 5rem 5rem 0.5rem;
  }

  ${media.laptop} {
    width: 60%;
  }

  ${media.desktop} {
    width: 50%;
  }

  ${PostItTag} {
    font-weight: 700;
    margin-right: 0.5rem;
  }

  ${PostItCopy} {
    margin-top: 2rem;
  }

  a {
    font-weight: 700;
    margin-right: 0.3rem;
    font-size: 20px;
    vertical-align: middle;
  }
`;

const PostItHeading = styled.h3`
  font-size: 30px;
  overflow: visible;
  text-transform: none;
  margin-bottom: 0.5rem;

  ${media.laptop} {
    font-size: 48px;
    margin-bottom: 1rem;
  }
`;

const SpecialContainer = styled.section`
  display: flex;
  flex-direction: row;
  margin-bottom: 7.5rem;
  align-items: flex-start;
  align-content: flex-start;

  /* image right */
  &:nth-child(even) {
    flex-direction: row-reverse;

    ${ShadowedBox} {
      margin-right: -10%;
      margin-left: -10%;
    }

    ${PostIt} {
      /* background-color: red; */

      margin-left: -10%;
      margin-right: -30%;

      ${media.laptop} {
        margin-right: -0%;
      }
    }

    ${PostItTags} {
      justify-content: flex-end;
    }

    ${PostItContent} {
      text-align: right;

      @media (max-width: 1023px) {
      }
    }
  }

  /* image left */
  &:nth-child(odd) {
    ${ShadowedBox} {
      margin-left: -10%;
      margin-right: -10%;
    }

    ${PostIt} {
      margin-right: -10%;
      margin-left: -30%;

      ${media.laptop} {
        margin-left: -0%;
      }

      /*
      margin-right: -10%;
      padding-right: 10%;
      margin-left: -30%;
      */
    }

    ${PostItContent} {
      text-align: left;
    }
  }
`;

const SpecialImageContainer = styled(ShadowedBox)`
  width: 100%;

  ${media.laptop} {
    width: 65%;
  }
`;
